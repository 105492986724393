import { useTheme } from '@mui/material/styles';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    <RocketLaunchIcon
      sx={{
        fontSize: 50, // Установите размер иконки по вашему усмотрению
        color: theme.palette.mode === 'light' ? 'primary' : 'secondary',
      }}
    />
  );
};

export default Logo;
