export const CHAT_LINKS = [
  {
    name: 'ChatGPT Next',
    url: 'https://app.nextchat.dev/#/?settings={"key":"{key}","url":"{server}"}',
    show: true
  },
  {
    name: 'ChatGPT Next [Beta]',
    url: 'https://hackerchat.btz.sh/#/?settings={"key":"{key}","url":"{server}"}',
    show: true
  },
];
